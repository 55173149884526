import httpClient from "@/common/http";

const creditScoringService = {
  // Fetch all uploads
  async fetchUploads() {
    try {
      const response = await httpClient.request("get", `/credit-scoring`, null, "client");
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Upload new file
  async uploadFile(formData) {
    try {
      const response = await httpClient.request(
        "post",
        `/credit-scoring/upload`,
        formData,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Delete file
  async deleteFile(uploadId) {
    try {
      const response = await httpClient.request(
        "delete",
        `/credit-scoring/${uploadId}`,
        null,
        "client",
      );
      return {
        result: true,
        value: true,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Replace uploaded file
  async replaceUploadedFile(uploadId, formData) {
    try {
      const response = await httpClient.request(
        "put",
        `/credit-scoring/replace/${uploadId}`,
        formData,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Get upload history for month/year
  getUploadHistory(month, year) {
    return httpClient.request("get", `/credit-scoring/history/${month}/${year}`, null, "client");
  },

  // Calculate credit scores
  async calculateScores(uploadId) {
    try {
      const response = await httpClient.request(
        "get",
        `/credit-scoring/calculate/${uploadId}`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
        status: error.response ? error.response.status : null,
        data: error.response ? error.response.data : null,
      };
    }
  },

  // Delete report
  async deleteReport(reportId) {
    try {
      const response = await httpClient.request(
        "delete",
        `/credit-scoring/report/${reportId}`,
        null,
        "client",
      );
      return {
        result: true,
        value: true,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Get reports for a specific year
  async fetchReportsByYear(year) {
    try {
      const response = await httpClient.request(
        "get",
        `/credit-scoring/reports/${year}`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Get report summaries for a specific year (without loan details)
  async fetchReportSummariesByYear(year) {
    try {
      const response = await httpClient.request(
        "get",
        `/credit-scoring/reports/${year}/summaries`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Get a specific report by ID
  async fetchReportById(reportId) {
    try {
      const response = await httpClient.request(
        "get",
        `/credit-scoring/report/${reportId}`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Get only the summary of a report by ID
  async fetchReportSummaryById(reportId) {
    try {
      const response = await httpClient.request(
        "get",
        `/credit-scoring/report/${reportId}/summary`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  async fetchReportByMonthYear(year, month) {
    try {
      console.log(`Fetching report for ${month}/${year} from API`);

      // Log the exact API URL being called for debugging
      const apiUrl = `/credit-scoring/reports/${year}/${month}`;
      console.log(`API URL: ${apiUrl}`);

      const response = await httpClient.request("get", apiUrl, null, "client");

      console.log(`API Response:`, response);

      return {
        result: true,
        value: response,
      };
    } catch (error) {
      console.error(`Error fetching report for ${month}/${year}:`, error);

      // Check if it's a 404 error specifically
      if (error.response && error.response.status === 404) {
        return {
          result: false,
          error: {
            message: `No report found for ${month} ${year}`,
            status: 404,
          },
        };
      }

      return {
        result: false,
        error,
      };
    }
  },

  // method to fetch only the summary of a report by month/year
  async fetchReportSummaryByMonthYear(year, month) {
    try {
      console.log(`Fetching report summary for ${month}/${year} from API`);

      const apiUrl = `/credit-scoring/reports/${year}/${month}/summary`;
      console.log(`API URL: ${apiUrl}`);

      const response = await httpClient.request("get", apiUrl, null, "client");

      console.log(`API Response (Summary):`, response);

      return {
        result: true,
        value: response,
      };
    } catch (error) {
      console.error(`Error fetching report summary for ${month}/${year}:`, error);

      // Check if it's a 404 error specifically
      if (error.response && error.response.status === 404) {
        return {
          result: false,
          error: {
            message: `No report found for ${month} ${year}`,
            status: 404,
          },
        };
      }

      return {
        result: false,
        error,
      };
    }
  },

  // Add a fallback method that tries different month formats (for summary data)
  async fetchReportSummaryByMonthYearWithFallback(year, monthInput) {
    // First try with the provided month format
    const firstResponse = await this.fetchReportSummaryByMonthYear(year, monthInput);
    if (firstResponse.result) {
      return firstResponse;
    }

    // If that fails, try different month formats

    // 1. First letter capitalized, rest lowercase
    const formattedMonth = monthInput.charAt(0).toUpperCase() + monthInput.slice(1).toLowerCase();
    if (formattedMonth !== monthInput) {
      console.log(`Trying with formatted month: ${formattedMonth}`);
      const secondResponse = await this.fetchReportSummaryByMonthYear(year, formattedMonth);
      if (secondResponse.result) {
        return secondResponse;
      }
    }

    // 2. Try with all uppercase
    const uppercaseMonth = monthInput.toUpperCase();
    if (uppercaseMonth !== monthInput) {
      console.log(`Trying with uppercase month: ${uppercaseMonth}`);
      const thirdResponse = await this.fetchReportSummaryByMonthYear(year, uppercaseMonth);
      if (thirdResponse.result) {
        return thirdResponse;
      }
    }

    // 3. Try with all lowercase
    const lowercaseMonth = monthInput.toLowerCase();
    if (lowercaseMonth !== monthInput) {
      console.log(`Trying with lowercase month: ${lowercaseMonth}`);
      const fourthResponse = await this.fetchReportSummaryByMonthYear(year, lowercaseMonth);
      if (fourthResponse.result) {
        return fourthResponse;
      }
    }

    // If all attempts fail, return the original error
    return firstResponse;
  },

  // Add a fallback method that tries different month formats
  async fetchReportByMonthYearWithFallback(year, monthInput) {
    // First try with the provided month format
    const firstResponse = await this.fetchReportByMonthYear(year, monthInput);
    if (firstResponse.result) {
      return firstResponse;
    }

    // If that fails, try different month formats

    // 1. First letter capitalized, rest lowercase
    const formattedMonth = monthInput.charAt(0).toUpperCase() + monthInput.slice(1).toLowerCase();
    if (formattedMonth !== monthInput) {
      console.log(`Trying with formatted month: ${formattedMonth}`);
      const secondResponse = await this.fetchReportByMonthYear(year, formattedMonth);
      if (secondResponse.result) {
        return secondResponse;
      }
    }

    // 2. Try with all uppercase
    const uppercaseMonth = monthInput.toUpperCase();
    if (uppercaseMonth !== monthInput) {
      console.log(`Trying with uppercase month: ${uppercaseMonth}`);
      const thirdResponse = await this.fetchReportByMonthYear(year, uppercaseMonth);
      if (thirdResponse.result) {
        return thirdResponse;
      }
    }

    // 3. Try with all lowercase
    const lowercaseMonth = monthInput.toLowerCase();
    if (lowercaseMonth !== monthInput) {
      console.log(`Trying with lowercase month: ${lowercaseMonth}`);
      const fourthResponse = await this.fetchReportByMonthYear(year, lowercaseMonth);
      if (fourthResponse.result) {
        return fourthResponse;
      }
    }

    // If all attempts fail, return the original error
    return firstResponse;
  },

  async fetchLoanTypeDistribution(year, month) {
    try {
      const response = await httpClient.request(
        "get",
        `/credit-scoring/reports/${year}/${month}/loan-type-distribution`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  async updateAllReportsWithLoanTypeDistributions() {
    try {
      const response = await httpClient.request(
        "post",
        `/credit-scoring/reports/update-loan-type-distributions`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },
  
  async exportReportToExcel(reportId) {
    try {
      const response = await httpClient.downloadExcel(`/credit-scoring/export-report/${reportId}`);

      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // RISK RATING CATEGORY METHODS

  // Fetch all risk rating categories
  async fetchRiskRatingCategories() {
    try {
      const response = await httpClient.request(
        "get",
        `/credit-scoring/risk-rating-categories`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Create a new risk rating category
  async createRiskRatingCategory(categoryData) {
    try {
      const response = await httpClient.request(
        "post",
        `/credit-scoring/risk-rating-categories`,
        categoryData,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Update a risk rating category
  async updateRiskRatingCategory(categoryId, categoryData) {
    try {
      const response = await httpClient.request(
        "put",
        `/credit-scoring/risk-rating-categories/${categoryId}`,
        categoryData,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Delete a risk rating category
  async deleteRiskRatingCategory(categoryId) {
    try {
      const response = await httpClient.request(
        "delete",
        `/credit-scoring/risk-rating-categories/${categoryId}`,
        null,
        "client",
      );
      return {
        result: true,
        value: true,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // RISK RATING METHODS

  // Fetch all risk ratings
  async fetchRiskRatings() {
    try {
      const response = await httpClient.request(
        "get",
        `/credit-scoring/credit-risk-ratings/all`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Fetch risk ratings by category
  async fetchRiskRatingsByCategory(categoryId) {
    try {
      const response = await httpClient.request(
        "get",
        `/credit-scoring/credit-risk-ratings/category/${categoryId}`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Save all risk ratings for a category (bulk save)
  async saveRiskRatingsForCategory(categoryId, riskRatings) {
    try {
      const response = await httpClient.request(
        "post",
        `/credit-scoring/credit-risk-ratings/${categoryId}`,
        { riskRatings },
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Save all risk ratings (bulk save)
  async saveRiskRatings(riskRatings) {
    try {
      const response = await httpClient.request(
        "post",
        `/credit-scoring/credit-risk-ratings`,
        { riskRatings },
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Update a single risk rating
  async updateRiskRating(ratingId, ratingData) {
    try {
      const response = await httpClient.request(
        "put",
        `/credit-scoring/credit-risk-ratings/${ratingId}`,
        ratingData,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Delete a risk rating
  async deleteRiskRating(ratingId) {
    try {
      const response = await httpClient.request(
        "delete",
        `/credit-scoring/credit-risk-ratings/${ratingId}`,
        null,
        "client",
      );
      return {
        result: true,
        value: true,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Create a single risk rating
  async createRiskRating(ratingData) {
    try {
      const response = await httpClient.request(
        "post",
        `/credit-scoring/credit-risk-ratings/single`,
        ratingData,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },

  // Get risk rating for a specific score
  async getRiskRatingForScore(score) {
    try {
      const response = await httpClient.request(
        "get",
        `/credit-scoring/credit-risk-ratings/score/${score}`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      // If no rating was found, don't treat as error
      if (error.response && error.response.status === 404) {
        return {
          result: true,
          value: null,
        };
      }
      return {
        result: false,
        error,
      };
    }
  },

  // Get risk rating for a specific score and loan type
  async getRiskRatingForScoreAndLoanType(score, loanType) {
    try {
      const response = await httpClient.request(
        "get",
        `/credit-scoring/credit-risk-ratings/score/${score}/loan-type/${loanType}`,
        null,
        "client",
      );
      return {
        result: true,
        value: response,
      };
    } catch (error) {
      // If no rating was found, don't treat as error
      if (error.response && error.response.status === 404) {
        return {
          result: true,
          value: null,
        };
      }
      return {
        result: false,
        error,
      };
    }
  },

  async fetchReportByMonthYearWithRetry(year, monthName) {
    try {
      // First try with the original format
      const response = await this.fetchReportByMonthYear(year, monthName);

      if (response.result) {
        return response;
      }

      // If it fails, attempt with adjusted month formatting
      // Some month names might be stored with different capitalization
      const standardizedMonthName =
        monthName.charAt(0).toUpperCase() + monthName.slice(1).toLowerCase();

      const secondResponse = await httpClient.request(
        "get",
        `/credit-scoring/reports/${year}/${standardizedMonthName}`,
        null,
        "client",
      );

      return {
        result: true,
        value: secondResponse,
      };
    } catch (error) {
      return {
        result: false,
        error,
      };
    }
  },
};

export default creditScoringService;