<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    persistent
  >
    <v-card>
      <v-card-title class="error-title" :class="titleColorClass">
        <v-icon left class="mr-2" :color="iconColor">{{ icon }}</v-icon>
        {{ title }}
      </v-card-title>
      
      <v-card-text class="pt-4">
        <div class="error-message">{{ message }}</div>
        
        <div v-if="details" class="error-details mt-3">
          <div v-if="isHTML" v-html="details"></div>
          <div v-else>{{ details }}</div>
        </div>
        
        <div v-if="suggestion" class="error-suggestion mt-3">
          <v-icon small color="info" class="mr-1">info</v-icon>
          <span v-html="suggestion"></span>
        </div>
      </v-card-text>
      
      <v-divider></v-divider>
      
      <v-card-actions class="pb-3 px-4">
        <v-spacer></v-spacer>
        
        <v-btn
          text
          color="grey darken-1"
          @click="close"
        >
          Close
        </v-btn>
        
        <v-btn
          v-if="showRetryButton"
          color="primary"
          text
          @click="retry"
        >
          Try Again
        </v-btn>
        
        <!-- Action button -->
        <template v-if="showActionButton">
          <v-btn
            :color="actionButtonColor"
            text
            @click="performAction"
          >
            {{ actionButtonText }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorDialog',
  
  props: {
    // Dialog visibility
    value: {
      type: Boolean,
      default: false
    },
    
    // Error title
    title: {
      type: String,
      default: 'Error'
    },
    
    // Main error message
    message: {
      type: String,
      required: true
    },
    
    // Additional error details
    details: {
      type: String,
      default: ''
    },
    
    // Error type: error, warning, info
    type: {
      type: String,
      default: 'error',
      validator: (val) => ['error', 'warning', 'info', 'success'].includes(val)
    },
    
    // Additional suggestion for the user
    suggestion: {
      type: String,
      default: ''
    },
    
    // Whether to show the retry button
    showRetryButton: {
      type: Boolean,
      default: false
    },
    
    // Whether to show an additional action button
    showActionButton: {
      type: Boolean,
      default: false
    },
    
    // Text for the action button
    actionButtonText: {
      type: String,
      default: 'Action'
    },
    
    // Color for the action button
    actionButtonColor: {
      type: String,
      default: 'primary'
    }
  },
  
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    
    icon() {
      switch(this.type) {
        case 'error':
          return 'error_outline';
        case 'warning':
          return 'warning';
        case 'info':
          return 'info';
        case 'success':
          return 'check_circle';
        default:
          return 'error_outline';
      }
    },
    
    iconColor() {
      switch(this.type) {
        case 'error':
          return 'error';
        case 'warning':
          return 'amber darken-2';
        case 'info':
          return 'info';
        case 'success':
          return 'success';
        default:
          return 'error';
      }
    },
    
    titleColorClass() {
      switch(this.type) {
        case 'error':
          return 'error--text text--darken-1';
        case 'warning':
          return 'amber--text text--darken-2';
        case 'info':
          return 'info--text';
        case 'success':
          return 'success--text';
        default:
          return 'error--text text--darken-1';
      }
    },
    
    // Check if details contains HTML
    isHTML() {
      return this.details && (
        this.details.includes('<') && 
        this.details.includes('>')
      );
    }
  },
  
  methods: {
    close() {
      this.dialog = false;
      this.$emit('close');
    },
    
    retry() {
      this.$emit('retry');
      this.close();
    },
    
    performAction() {
      this.$emit('action');
      this.close();
    }
  },

  created() {
    // Warn in development if showActionButton is true but no action listener is present
    if (process.env.NODE_ENV === 'development' && 
        this.showActionButton && 
        (!this.$listeners || !this.$listeners.action)) {
      console.warn('ErrorDialog: showActionButton is true but no action listener is registered');
    }
  }
}
</script>

<style scoped>
.error-title {
  font-weight: 500;
}

.error-message {
  font-size: 16px;
  font-weight: 500;
}

.error-details {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.error-suggestion {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-style: italic;
  display: flex;
  align-items: flex-start;
}

.error-suggestion :deep(a) {
  color: var(--v-primary-base);
  text-decoration: none;
}

.error-details :deep(.error-list) {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 24px;
}

.error-details :deep(.error-list li) {
  margin-bottom: 4px;
}
</style>