<template>
  <v-flex xs12>
    <v-card class="elevation-2 mb-3">
      <v-card-text class="pa-2">
        <v-layout row wrap align-center justify-center>
          <v-tooltip bottom v-for="(feature, index) in features" :key="index">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                :color="isActiveRoute(feature.route) ? 'primary' : ''"
                @click="navigateTo(feature.route)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>{{ feature.icon }}</v-icon>
                {{ feature.name }}
              </v-btn>
            </template>
            <span>{{ feature.description }}</span>
          </v-tooltip>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: 'CreditScoringFeaturesMenu',
  
  data() {
    return {
      features: [
        {
          name: 'Dashboard',
          icon: 'dashboard',
          route: '',
          description: 'View and analyze credit scoring data'
        },
        {
          name: 'Upload Data',
          icon: 'cloud_upload',
          route: 'upload-data',
          description: 'Upload loan information and risk data files'
        },
        {
          name: 'Mass Report',
          icon: 'library_books',
          route: 'mass-report',
          description: 'Generate reports for multiple loans'
        },
        {
          name: 'View Reports',
          icon: 'list',
          route: 'view-reports',
          description: 'Access and manage generated reports'
        },
        {
          name: 'Risk Rating Settings',
          icon: 'settings',
          route: 'risk-rating-settings',
          description: 'Configure risk rating grades and score bands'
        }
      ]
    };
  },
  
  methods: {
    navigateTo(route) {
      const path = `/app/credit-scoring/${route}`;
      this.$router.push(path).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error('Navigation error:', err);
        }
      });
    },
    
    isActiveRoute(route) {
      const currentPath = this.$route.path;
      const targetPath = `/app/credit-scoring/${route}`;
      
      // For the dashboard (empty route), do a strict comparison
      if (route === '') {
        return currentPath === '/app/credit-scoring' || currentPath === '/app/credit-scoring/';
      }
      
      // For other routes, check if the current path includes the target path
      return currentPath.includes(targetPath);
    }
  }
};
</script>